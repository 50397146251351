import React from "react";
import "../styles/MyImage.css";
function MyImage(props) {
    return (
        <img src={props.image} alt={props.value} draggable="false" />
    )

}



export default MyImage;